export const ADS_ROUTE_KEYS = {
  ads_home: "ads",
};

export const ADS_ROUTE_IDS = {
  [ADS_ROUTE_KEYS.ads_home]: "ads",
};
export const ADS_ROUTE_PATHNAMES = {
  [ADS_ROUTE_KEYS.ads_home]: ({ utmParams = {} }) => {
    const basePath = `/${ADS_ROUTE_IDS[ADS_ROUTE_KEYS.ads_home]}`;
    // Add UTM parameters if provided
    const utmString = new URLSearchParams(utmParams).toString();
    return utmString ? `${basePath}?${utmString}` : basePath;
  },
};
